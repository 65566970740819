import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FileService } from '@Services/file/file.service';
import { DownloadTemplates } from '@shared/enums/common/common';
import { get } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-download-template-overlay',
  standalone: true,
  imports: [ButtonModule, OverlayPanelModule, CommonModule],
  templateUrl: './download-template-overlay.component.html',
  styleUrl: './download-template-overlay.component.scss',
})
export class DownloadTemplateOverlayComponent {
  // Reference to the content div
  @ViewChild('templateContent') templateContentRef!: ElementRef;

  @Input() downloadTemplate: DownloadTemplates;

  visibleTemplateContent = false;

  constructor(private readonly fileService: FileService) {}

  downloadFile() {
    this.fileService
      .getImportTemplate(this.downloadTemplate)
      .then(() => {
        this.visibleTemplateContent = false;
      })
      .catch((error) => {
        console.error('Error downloading template:', error);
      });
  }

  toggleTemplateContent() {
    this.visibleTemplateContent = !this.visibleTemplateContent;
  }

  // HostListener listens for clicks on the entire document
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const classList = Array.from(get(event, 'target.classList', []));
    // Check if the click is on the download icon
    if (classList.includes('download-info-icon' as never)) {
      return;
    }
    const clickedInside = this.templateContentRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.visibleTemplateContent = false; // Hide the menu if clicked outside
    }
  }
}
